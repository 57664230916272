import Cookies from 'js-cookie'

const key = 'Authorization'
const storagekey = 'user_info'

export function setUserInfo(userinfo) {
    return localStorage.setItem(storagekey, JSON.stringify(userinfo))
}

export function getUserInfo() {
    return JSON.parse(localStorage.getItem(storagekey))
}

export function removeUserInfo() {
    return localStorage.removeItem(storagekey)
}

export function setToken(token) {
    return Cookies.set(key, token)
}

export function getToken() {
    return Cookies.get(key)
}

export function removeToken() {
    return Cookies.remove(key)
}

export function setRefreshToken(token) {
    return Cookies.set('RefreshToken', token)
}

export function getRefreshToken() {
    return Cookies.get('RefreshToken')
}

export function removeRefreshToken() {
    return Cookies.remove('RefreshToken')
}