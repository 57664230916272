import http from '@/utils/http'

export function downloadCommand(data) {
    return http({ method: 'post', url: '/order/download_command', data: data, responseType: 'blob' });
}

export function pushOrder(data) {
    return http({ method: 'post', url: '/order/push_command', data: data, headers: { 'Content-Type': 'multipart/form-data' }, unformat: true })
}

export function getOperationLogs(params) {
    return http({ method: 'get', url: '/operation_logs', params: params })
}

export function getRealTimeDataUp() {
    return http('/realTimeDataUp')
}

export function postRealTimeDataUp(data) {
    return http.post('/realTimeDataUp', data)
}