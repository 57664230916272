import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from 'nprogress'

// 進度條
import { getToken } from '@/utils/auth'


Vue.use(VueRouter)

export const routes = [
  {
    path: '/', component: () => import('@/layout/index'), children: [
      { path: '/', meta: { title: '下载订单' }, component: () => import('@/views/downloadOrder') },
      { path: '/push_order', meta: { title: '推单' }, component: () => import('@/views/orderPush') },
      { path: '/log', meta: { title: '操作日志' }, component: () => import('@/views/log') },
    ]
  },
  { path: '/login', meta: { title: '登录' }, props: () => ({ id:0 }), component: () => import('@/views/security') },
  { path: '/forget_password', meta: { title: '找回密码' }, props: () => ({ id: 1 }), component: () => import('@/views/security') },
  { path: '/update_password', meta: { title: '重置密码' }, props: () => ({ id: 2 }), component: () => import('@/views/security') },

  { path: '*', component: () => import('@/views/404') },

]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const whitelist = [
  '/login',
  '/forget_password',
  '/update_password'
]

const title = '跨境推单';

router.beforeEach(async (to, from, next) => {
  NProgress.start()
  if (to.meta.title) {
    document.title = `${title}-${to.meta.title}`
  }
  if (getToken()) {
    if (to.path === '/login') {
      next({ path: '/' })
      NProgress.done()
    } else {
      next()
      NProgress.done()
    }
  } else {
    if (whitelist.includes(to.path)) {
      next()
      NProgress.done()
    } else {
      next({ path: '/login' })
      NProgress.done()
    }
  }
})

export default router