import moment from 'moment'
/* 时间过滤器 - 日/月/年 */
// export const formatTime = value => moment(value).format('DD/MM/YYYY') 
export function formatTime(value) {
  return moment(value).format('DD/MM/YYYY')
}

export function formatDateTime(value) {
  return moment(value).format('YYYY-MM-DD HH:mm:ss')
}

export function formatDayTime(value) {
  return moment(value).format('HH:mm:ss')
}


// 价格金额格式化 (github: vuex --> demo --> shopping-cart -->currency.js)
const digitsRE = /(\d{3})(?=\d)/g
// value: 传入的值; currency: 货币符号; decimals小数位数;
export function currency(value, currency, decimals) {
  value = parseFloat(value)
  if (!isFinite(value) || (!value && value !== 0)) return ''
  currency = currency != null ? currency : ''
  decimals = decimals != null ? decimals : 2
  var stringified = Math.abs(value).toFixed(decimals)
  var _int = decimals
    ? stringified.slice(0, -1 - decimals)
    : stringified
  var i = _int.length % 3
  var head = i > 0
    ? (_int.slice(0, i) + (_int.length > 3 ? ',' : ''))
    : ''
  var _float = decimals
    ? stringified.slice(-1 - decimals)
    : ''
  var sign = value < 0 ? '-' : ''
  return sign + currency + head +
    _int.slice(i).replace(digitsRE, '$1,') +
    _float
}

export function Percentage(v) {
  return Number(v).toFixed(2);
}

export function getCountdown(value) {
  var t_s = new Date().getTime();
  var usedTime = value - t_s
  var leave1 = usedTime % (24 * 3600 * 1000);
  var hours = Math.floor(leave1 / (3600 * 1000));
 
  var leave2 = leave1 % (3600 * 1000);        //计算小时数后剩余的毫秒数
  var minutes = Math.floor(leave2 / (60 * 1000));
  var leave3 = leave2 % (60 * 1000)
  var seconds = Math.round(leave3 / 1000)
  if(hours < 10) {
    hours = `0${hours}`;
  }
  if(minutes < 10) {
    minutes = `0${minutes}`;
  }
  if(seconds < 10) {
    seconds = `0${seconds}`;
  }
  return `${hours}:${minutes}:${seconds}`
}

export function currencyNodecimals(value) {
  return currency(value, '', 0)
}

export function currency4(value) {
  return currency(value, '', 4) 
}

export function monthFilter(v) {
  switch(Number(v)) {
      case 1:
          return 'Jan'
      case 2:
          return 'Feb'
      case 3:
          return 'Mar'
      case 4:
          return 'Apr'
      case 5:
          return 'May'
      case 6:
          return 'Jun'
      case 7:
          return 'Jul'
      case 8:
          return 'Aug'
      case 9:
          return 'Sep'
      case 10:
          return 'Oct'
      case 11:
          return 'Nov'
      case 12:
          return 'Dec'
  }
}