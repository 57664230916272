// camelCase
// PascalCase
// snake_case
// kebab-case

const camelRe = /_(\w)/g
const snakeRe = /([A-Z])/g

// 轉成駝峯風格
function toCamelCase(data) {
    if (data instanceof Array) {
        let arr = []
        for (let i in data) {
            arr.push(toCamelCase(data[i]))
        }
        data = arr
    } else if (data !== null && typeof data === 'object') {
        let obj = {}
        for (let key in data) {
            let d = toCamelCase(data[key])
            let newKey = key.replace(camelRe, function (_, letter) {
                return letter.toUpperCase()
            })
            obj[newKey] = d
        }
        data = obj
    }
    return data
}

// 轉成下劃線風格
function toSnakeCase(data) {
    if (data instanceof Array) {
        let arr = []
        for (let i in data) {
            arr.push(toSnakeCase(data[i]))
        }
        data = arr
    } else if (data !== null && typeof data === 'object') {
        let obj = {}
        for (let key in data) {
            let d = toSnakeCase(data[key])
            let newKey = key.replace(snakeRe, function (_, letter) {
                return '_' + letter.toLowerCase()
            })
            obj[newKey] = d
        }
        data = obj
    }
    return data
}

export { toCamelCase, toSnakeCase }