import axios from 'axios'
import { toSnakeCase, toCamelCase } from './data-format'
import { setToken, getToken, getRefreshToken } from "@/utils/auth";

// 刷新 access_token 的接口
const refreshToken = () => {
    return http.get('/access_token', { params: { refresh_token: getRefreshToken() }}, true)
}

const http = axios.create({
    baseURL: process.env.VUE_APP_BASE_API || '/api/v1',
    timeout: 5000
})

http.interceptors.request.use(
    config => {
        const token = getToken()
        config.headers.common['Authorization'] = `Bearer ${token}`
        if (!config.unformat) {
            if(!config.url.match('/realTimeDataUp')) {
                config.params = toSnakeCase(config.params)
                config.data = toSnakeCase(config.data)
            }
        }
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

http.interceptors.response.use(
    response => {
        if (response.headers['content-type'] === 'application/json') {
            response.data = toCamelCase(response.data)
        }
        return response
    },
    error => {
        if (error.response.status === 401) {
            const { config } = error
            return refreshToken().then(res=> {
                const { accessToken } = res.data
                setToken(accessToken)
                config.headers.Authorization = `Bearer ${accessToken}`
                return http(config)
            }).catch(err => {
                console.log('抱歉，您的登录状态已失效，请重新登录！')
                return Promise.reject(err)
            })
        }
        return Promise.reject(error)
    }
)

export default http
