<template>
  <div id="app">
    <router-view />
  </div>
</template>


<script>
import { getToken } from "@/utils/auth";
import { getRealTimeDataUp, postRealTimeDataUp } from "@/api/order";
import { SignDataAsPEM } from "@/utils/client";
export default {
  data() {
    return {
      passwd: "88888888",
      signValue: "",
      payExchangeInfoHead: {},
      payExchangeInfoLists: {},
      serviceTime: "",
      sessionID: "",
      result: "",
    };
  },
  created() {
    setInterval(() => {
      if (getToken()) {
        getRealTimeDataUp().then((result) => {
          this.payExchangeInfoHead = result.data.payExchangeInfoHead;
          this.payExchangeInfoLists = result.data.payExchangeInfoLists;
          this.serviceTime = result.data.serviceTime;
          this.sessionID = result.data.sessionID;
          this.submit();
        });
      }
    }, 60000);
  },
  methods: {
    init() {
      let inData = `"sessionID":"${
        this.sessionID
      }"||"payExchangeInfoHead":"${JSON.stringify(
        this.payExchangeInfoHead
      )}"||"payExchangeInfoLists":"${JSON.stringify(
        this.payExchangeInfoLists
      )}"||"serviceTime":"${this.serviceTime}"`;
      console.log(inData, this.passwd);
      let that = this;
      SignDataAsPEM(inData, this.passwd, function (msg) {
        console.log(msg);
        that.result = JSON.stringify(msg);
        that.signValue = msg.Data[0];
        that.certNo = msg.Data[1];
        // 调用服务器接口
        let data = {
          sessionID: that.sessionID,
          payExchangeInfoHead: that.payExchangeInfoHead,
          payExchangeInfoLists: that.payExchangeInfoLists,
          serviceTime: that.serviceTime,
          certNo: that.certNo,
          signValue: that.signValue,
        };
        postRealTimeDataUp({
          payExInfoStr: JSON.stringify(data),
        }).then((_) => {
          console.log("post realtimeDataup 返回信息");
          console.log(_);
          if (_.data.code === 10000) {
            that.$message.success("海关调用成功！");
          } else if (_.data.code === 20000) {
            that.$message.error("海关调用失败！");
          }
        });
      });
    },
  },
};
</script>